import api from './axiosConfig';

// Логинизация
export const login = async (username, password) => {
  try {
    const response = await api.post('/auth/login', { username, password });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Неправильное имя пользователя или пароль');
    }
    throw new Error(error.message || 'Ошибка при логине');
  }
};
