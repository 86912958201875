import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button } from '@mui/material';
import Header from '../../components/Header';
import { generalStyles, containerStyles } from '../../styles/styles';

const HomePage = ({ role, onLogout }) => {
  const navigate = useNavigate();
  const username = "Root (Администратор)";
  const sections = role === 'admin'
    ? ['Мониторинг', 'Контроль доступа', 'Рассылка', 'Настройки']
    : role === 'operator'
    ? ['Мониторинг', 'Рассылка']
    : [];

  const handleSectionClick = (section) => {
    if (section === 'Мониторинг') {
      navigate('/monitoring');
    }
    if (section === 'Настройки') {
      navigate('/settings');
    }
    if (section === 'Контроль доступа') {
      navigate('/access-control');
    }
    if (section === 'Рассылка') {
      navigate('/mailing')
    }
  };

  return (
    <Box sx={generalStyles.pageBox}>
      <Helmet>
        <title>Домашняя страница</title>
      </Helmet>

      {/* Шапка */}
      <Header username={username} onLogout={onLogout} />

      {/* Основной контент с центрированием */}
      <Box sx={containerStyles.box}>
        <Box sx={containerStyles.contentBox}>
          {sections.map((section, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={() => handleSectionClick(section)}
              sx={containerStyles.button}
            >
              {section}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
