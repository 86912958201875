import api from './axiosConfig';

// Функция для загрузки списка чатов
export const fetchChats = async () => {
  try {
    const response = await api.get('/chats/');
    return response.data;
  } catch (err) {
    throw new Error(err.response?.data?.detail || 'Ошибка при загрузке чатов');
  }
};

// Функция для получения информации о чате
export const fetchChatInfo = async (chatId) => {
  try {
    const response = await api.get(`/chats/${chatId}`);
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении информации о чате:', error);
    throw error;
  }
};

// Функция для получения сообщений чата
export const fetchMessages = async (chatId) => {
  try {
    const response = await api.get(`/chats/${chatId}/messages`);
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении сообщений:', error);
    throw error;
  }
};

// Функция для отправки нового сообщения
export const sendMessage = async (chatId, message, token, senderType) => {
  try {
    const response = await api.post(
      `/chats/${chatId}/send-message`,
      { message, sender_type: senderType },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Ошибка при отправке сообщения:', error);
    throw error;
  }
};

// Функция для обновления параметра allow_gpt_responses
export const toggleAllowGPTResponses = async (chatId, newValue) => {
  try {
    await api.patch(`/chats/${chatId}/allow-gpt-responses`, { allow_gpt_responses: newValue });
  } catch (error) {
    console.error('Ошибка при обновлении allow_gpt_responses:', error);
    throw error;
  }
};
