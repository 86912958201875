import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, TextField, Button, Typography, Container, Switch, FormControlLabel, Snackbar, Alert } from '@mui/material';
import Header from '../../components/Header';
import { fetchSettings, saveSettings } from '../../api/settingsApi';

const SettingsPage = ({ username, onLogout }) => {
  const [allowAccessWithoutAuth, setAllowAccessWithoutAuth] = useState(false);
  const [enableGPTInChats, setEnableGPTInChats] = useState(true); // Новый переключатель
  const [prompt, setPrompt] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const data = await fetchSettings();
        setAllowAccessWithoutAuth(data.GLOBAL_ACCESS_WITHOUT_AUTH);
        setEnableGPTInChats(data.GLOBAL_ENABLE_GPT_IN_ALL_CHATS); // Загружаем новое поле
        setPrompt(data.PROMPT_TEXT);
        setToken(data.OPENAI_TOKEN);
      } catch (error) {
        console.error('Ошибка при загрузке настроек:', error);
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  const handleSaveChanges = async () => {
    try {
      const payload = {
        GLOBAL_ACCESS_WITHOUT_AUTH: allowAccessWithoutAuth,
        GLOBAL_ENABLE_GPT_IN_ALL_CHATS: enableGPTInChats, // Сохраняем новое поле
        PROMPT_TEXT: prompt,
        OPENAI_TOKEN: token,
      };

      await saveSettings(payload);
      console.log('Настройки успешно сохранены');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Ошибка при сохранении настроек:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return (
      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '20px' }}>
        Загрузка настроек...
      </Typography>
    );
  }

  return (
    <Box
      className="pageBox"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '64px',
      }}
    >
      <Helmet>
        <title>Настройки</title>
      </Helmet>

      <Header
        username={username}
        onLogout={onLogout}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1100,
          backgroundColor: 'white',
          boxShadow: 2,
        }}
      />

      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            bgcolor: '#fff',
            p: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" component="h1" sx={{ textAlign: 'center', fontSize: '1.5rem' }}>
            Настройки
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={allowAccessWithoutAuth}
                onChange={(e) => setAllowAccessWithoutAuth(e.target.checked)}
                color="primary"
              />
            }
            label="Разрешить доступ к боту без авторизации"
            sx={{ width: '100%' }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={enableGPTInChats}
                onChange={(e) => setEnableGPTInChats(e.target.checked)}
                color="primary"
              />
            }
            label="Включить GPT в чатах"
            sx={{ width: '100%' }}
          />

          <TextField
  label="Промпт"
  variant="outlined"
  fullWidth
  value={prompt}
  onChange={(e) => setPrompt(e.target.value)}
  multiline
  sx={{
    marginBottom: 2,
    '& .MuiOutlinedInput-root': {
      height: '150px', // Высота всего контейнера с границей
      display: 'flex',
      flexDirection: 'column', // Выравниваем элементы по вертикали
    },
    '& .MuiInputBase-input': {
      height: 'auto', // Позволяет тексту расширяться по мере ввода
      overflowY: 'auto', // Включаем вертикальную прокрутку
      flexGrow: 1, // Разрешаем текстовому полю заполнять доступное пространство
      minHeight: '120px', // Устанавливаем минимальную высоту для текста
      maxHeight: '120px', // Ограничиваем максимальную высоту текста
    },
  }}
  inputProps={{
    maxLength: 1000,
  }}
/>


          <TextField
  label="Токен"
  variant="outlined"
  fullWidth
  value={token}
  onChange={(e) => setToken(e.target.value)}
  multiline
  sx={{
    marginBottom: 2,
    '& .MuiOutlinedInput-root': {
      height: '100px', // Высота контейнера (границы)
      display: 'flex',
      flexDirection: 'column', // Контент выравнивается по вертикали
    },
    '& .MuiInputBase-input': {
      height: 'auto', // Текст будет увеличиваться по мере ввода
      maxHeight: '80px', // Ограничение высоты для текста
      overflowY: 'auto', // Прокрутка текста
      flexGrow: 1, // Текстовое поле будет заполнять доступное пространство
    },
  }}
  inputProps={{
    maxLength: 250, // Ограничение по длине
  }}
/>


          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveChanges}
            sx={{
              bgcolor: '#3f51b5',
              padding: '10px 20px',
              '&:hover': { bgcolor: '#303f9f' },
            }}
          >
            Сохранить изменения
          </Button>
        </Box>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Настройки успешно сохранены!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SettingsPage;
