import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, fetchCurrentUser, logout } from './store/authSlice';
import HomePage from './pages/HomePage/HomePage';
import MonitoringPage from './pages/MonitoringPage/MonitoringPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import AccessControlPage from './pages/AccessControlPage/AccessControlPage';
import CreateUserPage from './pages/CreateUserPage/CreateUserPage';
import LoginPage from './pages/LoginPage/LoginPage';
import NewsLetterPage from './pages/MailingPage/MailingPage'

const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(fetchCurrentUser()); // Загружаем данные пользователя, если токен существует
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={!isAuthenticated ? <LoginPage /> : <Navigate to="/home" />}
        />
        <Route
          path="/home"
          element={isAuthenticated ? <HomePage username={user?.username} role="admin" /> : <Navigate to="/login" />}
        />
        <Route
          path="/monitoring"
          element={isAuthenticated ? <MonitoringPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/monitoring/:chatId"
          element={isAuthenticated ? <MonitoringPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/settings"
          element={isAuthenticated ? <SettingsPage username={user?.username} /> : <Navigate to="/login" />}
        />
        <Route
          path="/access-control"
          element={isAuthenticated ? <AccessControlPage username={user?.username} /> : <Navigate to="/login" />}
        />
        <Route
          path="/create-user"
          element={isAuthenticated ? <CreateUserPage username={user?.username} /> : <Navigate to="/login" />}
        />
        <Route
          path="/mailing"
          element={isAuthenticated ? <NewsLetterPage username={user?.username} /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<Navigate to={isAuthenticated ? "/home" : "/login"} />} />
      </Routes>
    </Router>
  );
};

export default App;
