import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Switch, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage } from '../../api/chatApi';
import { fetchChatInfo, fetchMessages, toggleAllowGPTResponses } from '../../api/chatApi';
import ChatMessage from './ChatMessage';

const ChatWindow = ({ messages }) => {
  const [newMessage, setNewMessage] = useState('');
  const [chatMessages, setChatMessages] = useState(messages);
  const [chatInfo, setChatInfo] = useState(null);
  const { chatId } = useParams();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);

  const loadChatData = async () => {
    try {
      const chatData = await fetchChatInfo(chatId);
      setChatInfo(chatData);
      const messagesData = await fetchMessages(chatId);
      setChatMessages(messagesData);
    } catch (error) {
      console.error('Ошибка при загрузке данных чата:', error);
    }
  };

  useEffect(() => {
  loadChatData();

  const interval = setInterval(() => {
    loadChatData();
  }, 1000);
  return () => {
    clearInterval(interval);
  };
  }, [chatId]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        const senderType = user?.access_role || 'user';
        const token = localStorage.getItem('token');

        if (!token) {
          console.error('Токен не найден');
          return;
        }

        await sendMessage(chatId, newMessage, token, senderType);
        setNewMessage('');
        loadChatData();
      } catch (error) {
        console.error('Ошибка при отправке сообщения:', error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleToggleAllowGPT = async (newValue) => {
    try {
      await toggleAllowGPTResponses(chatId, newValue);
      setChatInfo((prevState) => ({
        ...prevState,
        allow_gpt_responses: newValue,
      }));
    } catch (error) {
      console.error('Ошибка при изменении allow_gpt_responses:', error);
    }
  };

  const sortedMessages = [...chatMessages].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {chatInfo && (
        <Box sx={{ p: 2, bgcolor: '#e0e0e0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">ID: {chatInfo.chat_tg_id}</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={chatInfo.allow_gpt_responses}
                onChange={(e) => handleToggleAllowGPT(e.target.checked)}
                name="allow_gpt_responses"
              />
            }
            label="Генерация GPT"
          />
        </Box>
      )}

      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          p: 1,
          bgcolor: '#f5f5f5',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {sortedMessages.map((msg) => (
          <ChatMessage key={msg.id} message={msg} />
        ))}
      </Box>

      <Box sx={{ display: 'flex', position: 'sticky', bottom: 0, bgcolor: '#fff', p: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Введите сообщение..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyPress} // Добавили обработчик события клавиатуры
          sx={{ mr: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage}>
          Отправить
        </Button>
      </Box>
    </Box>
  );
};

export default ChatWindow;
