import api from './axiosConfig';

// Функция для регистрации пользователя
export const registerUser = async (userData) => {
  try {
    const response = await api.post('/users/register', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Функция получения списка юзеров
export const fetchUsers = async () => {
  try {
    const response = await api.get('/users/');
    return response.data;
  } catch (error) {
    throw error.response?.data?.detail || 'Ошибка при загрузке пользователей';
  }
};

// Функция для получения текущего пользователя
export const fetchCurrentUserRequest = async (token) => {
  try {
    const response = await api.get('/users/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Ошибка при получении данных пользователя');
  }
};


export const sendNewsletter = async (message, mediaFile) => {
  try {
    const formData = new FormData();
    formData.append('message', message);

    // Добавляем файл только если он существует
    if (mediaFile) {
      formData.append('media', mediaFile);
    }

    const response = await api.post('/users/send-newsletter', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error during newsletter sending:', error);
    throw error.response?.data?.detail || 'Ошибка при отправке рассылки';
  }
};