import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, CircularProgress, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import { fetchChats } from '../../api/chatApi'; // Импортируем функцию для загрузки чатов

const MonitoringPage = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Загружаем список чатов
  const loadChats = async () => {
    try {
      setIsLoading(true);
      const chats = await fetchChats();
      setChatList(chats);
      if (chatId) {
        const foundChat = chats.find((chat) => chat.id.toString() === chatId);
        if (foundChat) {
          handleSelectChat(foundChat);
        } else {
          setError('Чат не найден');
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Обрабатываем выбор чата
  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    navigate(`/monitoring/${chat.id}`);
    const transformedMessages = chat.messages.flatMap((msg) => {
      const botMessages = msg.answer
        ? [{ id: `${msg.id}-bot`, sender: 'bot', message: msg.answer, created_at: msg.created_at }]
        : [];
      return [
        { id: msg.id, sender: msg.sender, message: msg.message, created_at: msg.created_at },
        ...botMessages,
      ];
    });
    setMessages(transformedMessages);
  };

  // Обрабатываем отправку сообщения
  const handleSendMessage = (newMessage) => {
    const userMessage = {
      id: messages.length + 1,
      sender: 'user',
      message: newMessage.message,
      created_at: newMessage.created_at,
    };

    const botMessage = newMessage.answer
      ? {
          id: `${messages.length + 1}-bot`,
          sender: 'bot',
          message: newMessage.answer,
          created_at: newMessage.created_at,
        }
      : null;

    setMessages((prev) => [...prev, userMessage, ...(botMessage ? [botMessage] : [])]);
  };

  useEffect(() => {
    loadChats();
  }, [chatId]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <Helmet>
        <title>Мониторинг чатов</title>
      </Helmet>

      <Header projectName="AI-HelpDesk Monitor" username="Root (Администратор)" onLogout={() => {}} />

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          bgcolor: '#fff',
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          mt: 10, // Отступ снизу шапки
          mb: 3,
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          align="center"
          sx={{
            mb: 3,
            fontSize: '1.5rem',
            bgcolor: '#fff',
            zIndex: 1,
          }}
        >
          Мониторинг чатов
        </Typography>

        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 128px)' }}>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflowY: 'auto',
                borderRadius: 2,
                boxShadow: 2,
                p: 2,
              }}
            >
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : error ? (
                <Typography color="error" align="center">
                  {error}
                </Typography>
              ) : (
                <ChatList chats={chatList} selectedChat={selectedChat} onSelectChat={handleSelectChat} />
              )}
            </Paper>
          </Grid>

          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 128px)' }}>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                borderRadius: 2,
                boxShadow: 2,
                p: 2,
                overflow: 'hidden',
              }}
            >
              {selectedChat ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      padding: 2,
                    }}
                  >
                    <ChatWindow chat={selectedChat} messages={messages} onSendMessage={handleSendMessage} />
                  </Box>
                </Box>
              ) : (
                <Typography variant="h6" align="center">
                  Выберите чат для просмотра
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MonitoringPage;
