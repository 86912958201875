import api from './axiosConfig';

// Функция для получения настроек
export const fetchSettings = async () => {
  try {
    const response = await api.get('/settings');
    return response.data;
  } catch (error) {
    console.error('Ошибка при загрузке настроек:', error);
    throw error;
  }
};

// Функция для сохранения настроек
export const saveSettings = async (settings) => {
  try {
    await api.put('/settings', settings);
  } catch (error) {
    console.error('Ошибка при сохранении настроек:', error);
    throw error;
  }
};
