import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Typography, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Modal } from '@mui/material';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import CreateUserPage from '../CreateUserPage/CreateUserPage';
import { fetchUsers } from '../../api/userApi'; // Импортируем сервис

const AccessControlPage = ({ username, onLogout }) => {
  const [openModal, setOpenModal] = useState(false); // Состояние для модалки
  const [users, setUsers] = useState([]); // Список пользователей
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние ошибки

  // Функция для загрузки пользователей из API
  const loadUsers = async () => {
    try {
      setLoading(true);
      const usersData = await fetchUsers(); // Получаем данные через сервис
      setUsers(usersData); // Устанавливаем данные пользователей
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers(); // Загружаем пользователей при монтировании компонента
  }, []);

  // Открытие и закрытие модалки
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Функция для перевода роли на русский
  const getRoleInRussian = (role) => {
    switch (role) {
      case 'user':
        return 'Пользователь';
      case 'admin':
        return 'Администратор';
      case 'operator':
        return 'Оператор';
      default:
        return role;
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', pt: '64px' }}>
      <Helmet>
        <title>Контроль доступа</title>
      </Helmet>

      <Header username={username} onLogout={onLogout} />

      <Container
        maxWidth="lg"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff',
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          mt: 3,
          mb: 3,
        }}
      >
        {/* Заголовок */}
        <Typography
          variant="h5"
          component="h1"
          sx={{
            mb: 3,
            fontSize: '1.5rem',
            textAlign: 'center',
            position: 'sticky',
            top: 0,
            bgcolor: '#fff',
            zIndex: 1,
          }}
        >
          Контроль доступа
        </Typography>

        {/* Кнопка для создания пользователя */}
        <Button
          variant="contained"
          color="primary"
          sx={{
            bgcolor: '#3f51b5',
            alignSelf: 'flex-end',
            '&:hover': { bgcolor: '#303f9f' },
            mb: 2,
          }}
          onClick={handleOpenModal}
        >
          Создать пользователя
        </Button>

        {/* Статус загрузки */}
        {loading ? (
          <Typography>Загрузка...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              flex: 1,
              overflowY: 'auto',
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Имя пользователя</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Роль</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Создано через</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Telegram Username</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Статус</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id} hover>
                    <TableCell>{user.service_username}</TableCell>
                    <TableCell>{getRoleInRussian(user.access_role)}</TableCell>
                    <TableCell>
                      {user.created_via === 'bot'
                        ? 'Бот (автоматически)'
                        : user.created_via === 'system'
                          ? 'Система (автоматически)'
                          : 'Сайт (вручную)'
                      }
                    </TableCell>
                    <TableCell>{user.tg_username || 'Не указан'}</TableCell>
                    <TableCell>{user.is_active ? 'Активен' : 'Неактивен'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>

      {/* Модальное окно для создания пользователя */}
      <Modal
  open={openModal}
  onClose={handleCloseModal}
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <Box
    sx={{
      width: '600px',        // Уменьшаем ширину до фиксированных 400px
      maxHeight: '90vh',     // Максимальная высота остается 90% от высоты экрана
      overflow: 'auto',
      bgcolor: 'white',
      p: 4,                  // Отступы
      borderRadius: 2,
      boxShadow: 24,
    }}
  >
    {/* Встраиваем страницу создания пользователя */}
    <CreateUserPage username={username} onLogout={onLogout} />
  </Box>
</Modal>
    </Box>
  );
};

export default AccessControlPage;
