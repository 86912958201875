import React from 'react';
import { Box, Typography } from '@mui/material';

const ChatMessage = ({ message }) => {
  const isBotOrOperator = message.sender === 'bot' || message.sender === 'operator' || message.sender === 'admin';

  // Попытаться распарсить дату, если она некорректна, вернуть 'Invalid Date'
  const formattedDate = message.created_at
    ? new Date(message.created_at).toLocaleString()
    : 'Invalid Date';  // Если дата некорректна, покажем 'Invalid Date'

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isBotOrOperator ? 'flex-end' : 'flex-start',  // Бот/Оператор справа, пользователь слева
        mb: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: '70%',
          p: 1,
          borderRadius: 2,
          bgcolor: isBotOrOperator ? '#d1c4e9' : '#e0f7fa', // Цвет фона для бота и оператора
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Заголовок для отправителя */}
        <Typography variant="body2" sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'gray' }}>
          {message.sender === 'operator'
            ? 'Оператор'
            : message.sender === 'bot'
            ? 'Бот'
            : message.sender === 'admin'
            ? 'Администратор'
            : 'Пользователь'}
        </Typography>

        {/* Сообщение */}
        <Typography variant="body1">{message.message}</Typography>

        {/* Ответ от бота (если есть) */}
        {message.answer && (
          <Box sx={{ mt: 1, bgcolor: '#f1f1f1', p: 1, borderRadius: 1 }}>
            <Typography variant="body2" color="textSecondary">
              Ответ от бота:
            </Typography>
            <Typography variant="body1">{message.answer}</Typography>
          </Box>
        )}

        {/* Дата */}
        <Typography
          variant="caption"
          sx={{ display: 'block', textAlign: 'right', mt: 0.5, fontStyle: 'italic', color: 'gray' }}
        >
          {formattedDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatMessage;
