import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, fetchCurrentUser } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, loading } = useSelector((state) => state.auth);

  const handleLogin = (e) => {
      e.preventDefault();
      dispatch(loginUser({ username, password }))
        .unwrap()
        .then(() => {
          dispatch(fetchCurrentUser()); // Загружаем данные пользователя после логина
          navigate('/home'); // Редирект на главную
        })
        .catch((err) => {
          console.error('Ошибка авторизации:', err);
        });
    };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: '#f3f4f6',
      }}
    >
      <Helmet>
        <title>Вход в AI-HelpDesk</title>
      </Helmet>

      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: '#ffffff',
          padding: '40px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" component="h1" sx={{ marginBottom: '20px' }}>
          AI-HelpDesk Monitor
        </Typography>

        <TextField
          label="Имя пользователя"
          variant="outlined"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ marginBottom: '15px' }}
        />

        <TextField
          label="Пароль"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />

        {error && (
          <Typography color="error" sx={{ marginBottom: '15px' }}>
            {error}
          </Typography>
        )}

        <Button
          variant="contained"
          fullWidth
          onClick={handleLogin}
          disabled={loading}
          sx={{
            backgroundColor: '#1e3a8a',
            color: '#ffffff',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            '&:hover': { backgroundColor: '#27406e' },
          }}
        >
          {loading ? 'Загрузка...' : 'Войти'}
        </Button>
      </Container>
    </Box>
  );
};

export default LoginPage;
