import React, { useState } from 'react';
import { Box, Button, Typography, TextField, FormControlLabel, Checkbox, Snackbar, Alert, MenuItem } from '@mui/material';
import { registerUser } from '../../api/userApi';  // Импортируем функцию для регистрации пользователя

const CreateUserPage = ({ username, onLogout }) => {
  const [tgId, setTgId] = useState('');
  const [tgUsername, setTgUsername] = useState('');
  const [serviceUsername, setServiceUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accessRole, setAccessRole] = useState('user');
  const [allowTgLogin, setAllowTgLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Функция для отправки данных на сервер
  const handleRegisterUser = async () => {
    const userData = {
      tg_id: tgId,
      tg_username: tgUsername,
      service_username: serviceUsername,
      password: password,
      access_role: accessRole,
      allow_tg_login: allowTgLogin,
    };

    try {
      setLoading(true);
      await registerUser(userData);  // Используем функцию для регистрации
      setLoading(false);
      setOpenSnackbar(true); // Показать уведомление об успешной регистрации
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.detail || 'Ошибка при регистрации');
    }
  };

  // Закрытие уведомления
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Валидация для поля tgId (только цифры)
  const handleTgIdChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Только цифры
      setTgId(value);
    }
  };

  // Валидация для поля tgUsername (без @)
  const handleTgUsernameChange = (e) => {
    const value = e.target.value;
    if (!value.startsWith('@')) {
      setTgUsername(value);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}> {/* Уменьшена ширина */}
      <Typography variant="h6" sx={{ mb: 2 }}>Создание пользователя</Typography>

      <TextField
        label="Telegram ID"
        value={tgId}
        onChange={handleTgIdChange}
        fullWidth
        sx={{ mb: 2 }}
        required
        error={!tgId}
        helperText={tgId ? '' : 'Это поле обязательно'}
      />
      <TextField
        label="Telegram Username"
        value={tgUsername}
        onChange={handleTgUsernameChange}
        fullWidth
        sx={{ mb: 2 }}
        required
        error={!tgUsername}
        helperText={tgUsername ? '' : 'Это поле обязательно'}
      />
      <TextField
        label="Service Username"
        value={serviceUsername}
        onChange={(e) => setServiceUsername(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
        required
        error={!serviceUsername}
        helperText={serviceUsername ? '' : 'Это поле обязательно'}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Выпадающее меню для роли */}
      <TextField
        select
        label="Роль"
        value={accessRole}
        onChange={(e) => setAccessRole(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      >
        <MenuItem value="user">Пользователь</MenuItem>
        <MenuItem value="admin">Администратор</MenuItem>
        <MenuItem value="operator">Оператор</MenuItem>
      </TextField>

      <FormControlLabel
        control={
          <Checkbox
            checked={allowTgLogin}
            onChange={(e) => setAllowTgLogin(e.target.checked)}
          />
        }
        label="Разрешить вход через Telegram"
      />

      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleRegisterUser}
        disabled={loading || !tgId || !tgUsername || !serviceUsername} // Отключение кнопки, если обязательные поля пусты
      >
        {loading ? 'Создание...' : 'Создать'}
      </Button>

      {/* Уведомление с помощью Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Пользователь успешно зарегистрирован!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateUserPage;
