import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, Snackbar, Alert } from '@mui/material';
import Header from '../../components/Header';
import { sendNewsletter } from '../../api/userApi'; // импортируем функцию для отправки рассылки

const NewsLetterPage = ({ username, onLogout }) => {
  const [postText, setPostText] = useState('');
  const [mediaFile, setMediaFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMediaFile(file);
      setFileName(file.name);
    }
  };

  const handleSendNewsletter = async () => {
    if (!postText) {
      alert('Необходимо заполнить все поля!');
      return;
    }

    setLoading(true);

    try {
      // Отправка рассылки
      await sendNewsletter(postText, mediaFile);
      setLoading(false);
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      alert(error || 'Произошла ошибка при отправке рассылки');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '64px',
      }}
    >
      <Header
        username={username}
        onLogout={onLogout}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1100,
          backgroundColor: 'white',
          boxShadow: 2,
        }}
      />

      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            bgcolor: '#fff',
            p: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" component="h1" sx={{ textAlign: 'center', fontSize: '1.5rem' }}>
            Создание новостного поста
          </Typography>

          <TextField
            label="Текст поста"
            variant="outlined"
            fullWidth
            value={postText}
            onChange={(e) => setPostText(e.target.value)}
            multiline
            sx={{
              marginBottom: 2,
              '& .MuiOutlinedInput-root': {
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
              },
              '& .MuiInputBase-input': {
                height: 'auto',
                overflowY: 'auto',
                flexGrow: 1,
                minHeight: '120px',
                maxHeight: '120px',
              },
            }}
            inputProps={{
              maxLength: 3000,
            }}
          />

          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{
              marginBottom: 1,
              bgcolor: '#fff',
              padding: '10px 20px',
              border: '1px solid #3f51b5',
              '&:hover': { bgcolor: '#f5f5f5' },
            }}
          >
            Загрузить медиафайл
            <input
              type="file"
              hidden
              accept="image/jpeg, image/png, image/gif"
              onChange={handleFileChange}
              multiple={false}
            />
          </Button>

          {fileName && (
            <Typography variant="body2" sx={{ color: 'gray', textAlign: 'center' }}>
              Загруженный файл: {fileName}
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSendNewsletter}
            sx={{
              padding: '10px 20px',
              bgcolor: '#3f51b5',
              '&:hover': { bgcolor: '#303f9f' },
            }}
            disabled={loading}
          >
            {loading ? 'Отправка...' : 'Начать рассылку'}
          </Button>
        </Box>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Новостной пост успешно отправлен!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewsLetterPage;
