import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItemText, Typography, Divider, Box } from '@mui/material';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { fetchChats } from '../../api/chatApi';  // Импортируем функцию для получения чатов

const ChatList = ({ selectedChat, onSelectChat }) => {
  const [chats, setChats] = useState([]);  // Состояние для списка чатов

  // Загружаем чаты при монтировании компонента
  useEffect(() => {
    const loadChats = async () => {
      try {
        const chatData = await fetchChats();
        setChats(chatData);
      } catch (error) {
        console.error('Ошибка при загрузке чатов:', error);
      }
    };
    loadChats();
  }, []);  // Запрос на получение чатов выполняется один раз при монтировании

  return (
    <List component="nav" disablePadding>
      {chats.map((chat, index) => {
        const lastMessage = chat.messages?.[0];

        return (
          <React.Fragment key={chat.id}>
            <ListItemButton
              selected={selectedChat?.id === chat.id}
              onClick={() => onSelectChat(chat)}
              sx={{
                py: 1,
                px: 2,
                alignItems: 'flex-start',
                '&.Mui-selected': {
                  bgcolor: 'rgba(0, 0, 255, 0.05)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 255, 0.1)',
                  },
                },
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, width: '100%' }}>
                <Typography variant="body1">Чат TG ID: {chat.chat_tg_id}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Создан: {format(new Date(chat.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru })}
                </Typography>
                {lastMessage && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 0.5, fontStyle: 'italic' }}
                  >
                    {lastMessage.author
                      ? `${lastMessage.author}: ${lastMessage.message.slice(0, 50)}...`
                      : lastMessage.message.slice(0, 50)}
                  </Typography>
                )}
              </Box>
            </ListItemButton>

            {index < chats.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ChatList;
