import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'; // Импортируем хуки из Redux
import { Link } from 'react-router-dom';
import { logout } from '../store/authSlice'; // Импортируем экшен для логаута
import { headerStyles } from '../styles/styles';


// Функция для перевода роли на человекочитаемый формат
const getRoleInRussian = (role) => {
  switch (role) {
    case 'admin':
      return 'Администратор';
    case 'user':
      return 'Пользователь';
    case 'operator':
      return 'Оператор';
    default:
      return 'Неизвестная роль';
  }
};

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user); // Данные пользователя из Redux

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppBar position="fixed" sx={headerStyles.appBar}>
      <Toolbar sx={headerStyles.toolbar}>
        <Typography
          variant="h6"
          sx={headerStyles.title}
          component={Link}
          to="/home"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          AI-HelpDesk Monitor
        </Typography>

        <Typography variant="body1" sx={{ mr: 2 }}>
          {user?.service_username
            ? `${user.service_username} (${getRoleInRussian(user.access_role)})`
            : 'Неизвестно'}
        </Typography>
        <Button color="inherit" onClick={handleLogout} sx={headerStyles.logoutButton}>
          Выйти
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
