import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; // Импортируем Provider
import store from './store/index'; // Импортируем Redux-хранилище
import App from './App'; // Импортируем главный компонент приложения
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') // Убедитесь, что ID соответствует вашему корневому элементу в HTML
);